import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  description?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '/',
    name: 'Home',
    type: 'link',
    description: 'Menu da Tela Inicial',
    icon: 'home'
  },
  {
    state: 'escala',
    name: 'Escala',
    type: 'sub',
    description: 'Menu de visualização de escala individual e por unidade',
    icon: 'straighten',
    children: [
      {state: 'medica', name: 'Escala Individual'},
      {state: 'unidade', name: 'Unidades Médicas'},
      {state: 'distribuicao', name: 'Distribuição de Contratos'},
    ]
  },
  {
    state: 'plantoes',
    name: 'Contagem de Plantões',
    type: 'link',
    description: 'Menu de visualização de Plantões por Período',
    icon: 'assignment'
  },
  {
    state: 'pagamento',
    name: 'Folha de Pagamento',
    type: 'sub',
    description: 'Menu de visualização de Folha de Pagamento - Holerite',
    icon: 'payment',
    children: [
      {state: 'holerite', name: 'Holerite'},
    ]
  },
  {
    state: 'imposto-renda',
    name: 'Imposto de Renda',
    type: 'sub',
    description: 'Menu de visualização de Ficha Financeira e Cédula C',
    icon: 'payment',
    children: [
      {state: 'ficha-financeira', name: 'Ficha Financeira'},
      {state: 'cedulac', name: 'Cédula C'},
    ]
  },
  {
    state: 'repasse-medicos',
    name: 'Repasse de Proc. Médicos',
    type: 'link',
    description: 'Menu de visualização de Repasse de Procedimentos Médicos',
    icon: 'account_balance_wallet'
  },
  {
    state: 'perfil',
    name: 'Perfil',
    type: 'link',
    description: 'Menu de visualização e edição de dados',
    icon: 'person'
  },
  {
    state: 'novasenha',
    name: 'Alterar Senha',
    type: 'link',
    description: 'Menu para Alterar a sua Senha',
    icon: 'lock'
  },
];

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu) {
    MENUITEMS.push(menu);
  }
}
