import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/filter';

import { environment } from './../../../environments/environment.prod';

import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public lastUrl: string;

  constructor(private http: HttpClient, private router: Router) {
    this.router.events.filter(e => e instanceof NavigationEnd)
      .subscribe((e: NavigationEnd) => this.lastUrl = e.url);
   }

  login(login: string, senha: string): Observable<any> {
    return this.http.post<any>(`${environment.apiAuth}/auth`, {login, senha})
      .pipe(
        map((usuario: any) => {

          if (usuario.id) {
            sessionStorage.setItem('token', usuario.accessToken);
            sessionStorage.setItem('usuario', btoa(JSON.stringify(usuario)));
          }

          return usuario;
        })
      )
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiAuth}/forgot/password`, { email });
  }

  isLoggedIn(): boolean {
    return sessionStorage.getItem('token') ? true : false;
  }

  user(): any {
    return sessionStorage.getItem('usuario') ? JSON.parse(atob(sessionStorage.getItem('usuario'))) : false;
  }

  handleLogin(path: string = this.lastUrl): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    sessionStorage.clear();
    this.handleLogin();
  }

  clearStorage(): void {
    sessionStorage.clear();
  }
}
