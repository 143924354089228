import { CanLoad, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

import { LoginService } from "../services/login.service";

@Injectable()
export class LoggedinGuard implements CanLoad, CanActivate {

  constructor(private loginService: LoginService) { }

  private checkAuthenticate(path: string): boolean {
    const loggedIn = this.loginService.isLoggedIn();

    if (!loggedIn) {
      sessionStorage.clear();
      this.loginService.handleLogin(`/${path}`);
    }

    return loggedIn;
  }

  canLoad(route: Route): boolean {
    return this.checkAuthenticate(route.path);
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    return this.checkAuthenticate(activatedRoute.routeConfig.path);
  }
}
