import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable, Injector } from "@angular/core";

import { environment } from './../../../environments/environment.prod';
import { LoginService } from './../services/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loginService = this.injector.get(LoginService);

    const urlRequest = request.url;

    if (urlRequest.split('/')[2] == 'viacep.com.br')
      return next.handle(request);


    if (loginService.isLoggedIn()) {
      const authRequest = request.clone({setHeaders: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}});

      return next.handle(authRequest);

    } else {
      return next.handle(request);
    }


  }
}
