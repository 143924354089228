export const environment = {
  production: true,

  apid: "http://localhost:3001/aWNlYQ==/api",
  apiAuthd: "http://localhost:3001/aWNlYQ==",

  api: "https://api.easytecsis.com.br/aWNlYQ==/api",
  apiAuth: "https://api.easytecsis.com.br/aWNlYQ==",

  viaCep: "//viacep.com.br/ws",
};
