import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { retry, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private toastr: ToastrService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            switch (error.status) {
              case 0:
                errorMessage = `Aviso: Servidor está fora do ar. estamos resolvendo. Por favor, Aguarde para tentar novamente!!`;
                this.router.navigate(['/login']);
                break;
              case 401:
                errorMessage = `Aviso: Email/Senha estão incorretos!`;
                this.router.navigate(['/login']);
                break;
              case 403:
                errorMessage = `Aviso: ${error.error.message}`;
                break;
              case 404:
                errorMessage = `Aviso: ${error.error.message}`;
                break;
              case 500:
                errorMessage = `Aviso: ${error.error.message}`;
                break;
            }

          }

          this.toastr.clear();
          this.toastr.error(errorMessage, null, {
            positionClass: 'toast-bottom-center',
            progressAnimation: 'decreasing',
            progressBar: true,
            timeOut: 8000
          });

          return throwError(errorMessage);
        })
      )
  }
}
